import { isEqual, sortBy } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, finalize, map, takeUntil } from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService, IntegrationsService, SettingsContentHubService, SettingsService } from '@sociuu/modules';

import { CoreComponent } from '@app/core/core.component';
import { IconSnackBarComponent } from '@sociuu/utility/icon-snack-bar';

import { TagsService } from '@app/modules/admin/tags/tags.service';

import {
  Source,
  SOURCES,
  CompanyPage,
  Integration,
  UserInfoDto,
  FalconIntegration,
  VideoAnalyticsNetwork,
} from '@sociuu/interfaces';
import { ITag, ITagList } from '@lib/tag.interface';
import { MODAL_TYPE } from '@sociuu/utility/app.util';
import { PermissionsEnum, UserPermissions } from '@app/app.permissions';

enum PERSONIO_CONTROL {
  ACCOUNT_NAME = 'personio_acc_name',
  ACCOUNT_DOMAIN = 'personio_domain',
  PERSONIO_URL = 'personio_url',
  AUTO_ARCHIVE = 'autoArchive',
  AUTO_UNARCHIVE = 'autoUnarchive',
}

export interface IStatus {
  id: number;
  name: string;
  value: any;
}

export interface IPersonio {
  type: string;
  title: string;
  tags?: number[];
  account_name: string;
  account_domain: string;
  available_all: number;
  network_ids?: number[];
  source_type_id: number;
  auto_archive: boolean;
  auto_unarchive: boolean;
  job_details_url: string;
  content_expires_after_days?: number;
}

export interface IPersonioUpdate {
  source_id: number;
  network_ids: number[];
  tags: number[];
  available_all: boolean;
  auto_archive: boolean;
  auto_unarchive: boolean;
  title: string;
  updated_at?: string;
  created_at?: string;
  content_expires_after_days?: number;
  account_domain?: string;
}

interface IPersonioResponse {
  source: IPersonio;
  source_data: IPersonioUpdate;
  messages: string[];
}

export interface IPersonioResponses {
  data: IPersonioResponse;
  messages: string[];
}

export const PERSONIO_URL_REGEX: RegExp = /^[\w-]+\.jobs\.personio\.(de|com)\/job\/(\{position_id\}|[\w/]+)$/;

interface IControl { name: string, control?: AbstractControl };
export enum PersonioDomains {
  ['Personio.com'] = 'personio.com',
  ['Personio.de'] = 'personio.de',
}
export enum personioVerificationStates {
  valid = 'valid',
  warning = 'warning',
  invalid = 'invalid',
}
const PERSONIO_CUSTOM_REGEX: RegExp = /{position_id}/;


@Component({
  selector: 'sociuu-create-source-new',
  templateUrl: './create-source-new.component.html',
  styleUrls: ['./create-source-new.component.scss'],
})
export class CreateSourceNewComponent extends CoreComponent implements OnInit, AfterContentChecked {
  @ViewChild('matDialogContent', { static: false }) matDialogContent: ElementRef;

  addSourcePayloadForm: FormGroup;
  tagsForm: FormGroup;
  public todayDate = new Date();

  savingLoader: boolean;
  canAdd: boolean;
  addSourceProcess: boolean;
  integrations: Integration[] = [];
  falconIntegrations: FalconIntegration[];
  _companyPages: Map<string, CompanyPage> = new Map();

  tags: any[] = [];
  tagsToSave: any[] = [];
  public tagsModel: ITag[];
  private tagsLastPage: number = 1;
  private tagsCurrentPage: number = 1;
  socialNetworks: any[] = [];

  loadingIndicator: boolean;
  companyLoadingIndicator = false;
  sourceTypeLoadingIndicator: boolean;
  requestBody: any;

  userInfo: UserInfoDto;

  hasSourceCreateLinkedinPermission = false;
  hasSourceCreateFacebookPermission = false;
  hasSourceCreateRssPermission = false;
  hasSourceCreateFalconPermission = false;
  public hasSourceCreateEmplyPermission: boolean = false;
  public hasSourceCreatePersonioPermission: boolean = false;

  sourceType: string;
  isContentPublic = false;
  isSourceNameShow = false;
  selectedTagsOnEdit: any[] = [];
  companyIntegrations: any[] = [];
  selectedCompanyPage: any;

  falconNetworks: any[] = [];
  falconNetworksToSave = [];
  selectedFalconNetworksOnEdit: any[] = [];
  falconNetworksLoadingIndicator: boolean;

  falconChannels: any[] = [];
  falconChannelsToSave = [];
  selectedFalconChannelsOnEdit: any[] = [];
  falconChannelsLoadingIndicator: boolean;

  falconLabels: any[] = [];
  falconLabelsToSave = [];
  selectedFalconLabelsOnEdit: any[] = [];
  falconLabelsLoadingIndicator: boolean;
  public tagsUpdated: boolean = false;

  labelKeyword: string;
  panelOpenState = false;

  public isPersonioDefaultURLEnabled: boolean = true;
  public isVerifyingAccName: boolean = false;
  public isVerifyingAccURL: boolean = false;
  public personioVerificationStatuses: typeof personioVerificationStates = personioVerificationStates;
  private $personioAccName: Subscription;
  private $personioURL: Subscription;
  public personioPositionId: string = '{position_id}';
  public personioAccValidationStatus: personioVerificationStates = personioVerificationStates.invalid;
  public personioURLValidationStatus: personioVerificationStates = personioVerificationStates.valid;
  public personioDomains: object[] = [
    { key: 'Personio.com', value: PersonioDomains['Personio.com'] },
    { key: 'Personio.de', value: PersonioDomains['Personio.de']},
  ];

  public sanitizedUrl;
  public currentPositionId: string;
  public personioCustomURL: string = '';
  public currentValidPersonioUrl: string;

  public readonly directShareTooltipText: string = `When the posts are shared on the same network as the source, the posts will be formatted as a Reshare. If the post is shared on another network than this source, the shared post will show the original link from the source.`;
  public readonly tagsFromBrandwatchTooltipText: string = `For example, if a Brandwatch label "Social" is imported, it will be called "f: Social" in Sociuu.`;
  public readonly existingTagsTooltipText: string = `If the tag does not exist in Sociuu it will be created. If it exists, the existing tag will be used`;
  public readonly autoArchiveTooltipText: string = `If a Personio position is republished it will automatically be unarchived if it previously have been automatically been archived.`;
  public readonly autoUnarchiveTooltipText: string = `If a Personio position is no longer available, it means it's been 'unpublished' or 'archived' in Personio and it will automatically be archived.`;
  public readonly postInSociuuHubTooltipText: string = `The post will be available in SociuuHub as well as in Content Hub.`;
  public readonly sendToBrandwatchTooltipText: string = `Enable to automatically send new Content Hub additions from this source to Brandwatch's Content Pool.`;
  public readonly preventDuplicateTooltipText: string = `By enabling this, you will see a ignore-on-sociuu label in Brandwatch.`;
  public readonly fromMediaLibraryTooltipText: string = `Enable this feature to replace all incoming content images from this source with random images from your Media Library, regardless of original content`;
  public readonly deadlineAsExpirationTooltipText: string = `Use the deadline in Emply as the expiration date for the job post in Sociuu Hub.`;
  public readonly setActiveNetworksTooltipText: string = `Set active networks.`;
  public readonly setTagsTooltipText: string = `Set tags.`;
  public readonly unchangedTagsInfo: string = `All changes will apply to existing content. Tags added or removed from the Source Tag list will update all content, regardless of previous manual adjustments. Tags not part of the changes will remain untouched. This cannot be undone`;

  public mlImagesCount: number;

  public get companyPages(): CompanyPage[] {
    return [...this._companyPages.values()];
  }

  public get personioDefalutURL(): string {
    const accName = this.personioAccNameControl?.value;
    const domain = this.personioDomainControl?.value;
    const defaultURL: string = `${accName || '{personio_account_name}'}.jobs.${domain || PersonioDomains['Personio.com']}/job/{position_id}`;
    this.currentValidPersonioUrl = `${accName || '{personio_account_name}'}.jobs.${domain || PersonioDomains['Personio.com']}/job/${this.currentPositionId || '{position_id}'}`;
    return defaultURL;
  }

  public shouldScrollToBottom(): boolean {
    return !this.sourceTypeLoadingIndicator && this.data?.type === 'EDIT' && this.hasTagsChanged && this.tagsUpdated;
  }

  public get personioControls(): IControl[]  {
    const personioControls: IControl[] = [
      {
        name: PERSONIO_CONTROL.ACCOUNT_NAME, control: this.fb.control('', { validators: [Validators.required]}),
      },
      {
        name: PERSONIO_CONTROL.ACCOUNT_DOMAIN, control: this.fb.control(PersonioDomains['Personio.com'], [Validators.required]),
      },
      {
        name: PERSONIO_CONTROL.PERSONIO_URL, control: this.fb.control({ value: '', disabled: true}, { validators: [Validators.required, Validators.pattern(PERSONIO_URL_REGEX)] }),
      },
      {
        name: PERSONIO_CONTROL.AUTO_ARCHIVE, control: this.fb.control(true, [Validators.required]),
      },
      {
        name: PERSONIO_CONTROL.AUTO_UNARCHIVE, control: this.fb.control(true, [Validators.required]),
      },
    ];
    return personioControls;
  }

  public get shouldAddShowInsertPositionID(): boolean {
    if (this.addSourcePayloadForm.get('type').value === SOURCES.PERSONIO) {
      const currentUrl: string = this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).value;
      return !(currentUrl.includes(this.personioPositionId));
    } else return false;
  }

  public get personioControlNames(): string[] {
    return this.personioControls.map((control: IControl): string => control.name);
  }

  public get isEmplyDate(): boolean {
    return this.addSourcePayloadForm.get('expires_at_deadline').value;
  }

  public get isPublic(): boolean {
    return this.addSourcePayloadForm.get('availableAll').value;
  }

  public get contentExpireEnabled(): boolean {
    return !!(this.addSourcePayloadForm?.get('content_expire_enabled')?.value === true);
  }

  public get directShareEnabled(): boolean {
    return !!(this.addSourcePayloadForm?.get('direct_share')?.value === true);
  }

  public get shouldCallFunction(): boolean {
    return !this.sourceTypeLoadingIndicator && this.data && this.data.type === MODAL_TYPE.EDIT && this.hasTagsChanged;
  }

  public get currentUser(): UserInfoDto {
    return this.authService.currentUser.value;
  }

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private tagsService: TagsService,
    private settingsService: SettingsService,
    private integrationsService: IntegrationsService,
    private settingsContentHubService: SettingsContentHubService,
    public dialogRef: MatDialogRef<CreateSourceNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      networks: VideoAnalyticsNetwork[];
      type: MODAL_TYPE;
      source: Source;
    }
  ) {
    super();
    this.hasSourceCreateLinkedinPermission = UserPermissions[PermissionsEnum.SourceCreateLinkedin] || UserPermissions[PermissionsEnum.Fullprivilege];
    this.hasSourceCreateFacebookPermission = UserPermissions[PermissionsEnum.SourceCreateFacebook] || UserPermissions[PermissionsEnum.Fullprivilege];
    this.hasSourceCreateRssPermission = UserPermissions[PermissionsEnum.SourceCreateRss] || UserPermissions[PermissionsEnum.Fullprivilege];
    this.hasSourceCreateFalconPermission = UserPermissions[PermissionsEnum.SourceCreateFalcon] || UserPermissions[PermissionsEnum.Fullprivilege];
    this.hasSourceCreateEmplyPermission = true;
    this.hasSourceCreatePersonioPermission = true
    this.createSourcePayloadForm();
  }

  public ngOnInit(): void {
    this.getTags();
    this.getClientNetworks();
    this.selectCompany();
    this.settingsService.getMLImagesCount().subscribe({
      next: (val) => {
        this.mlImagesCount = val?.data?.fallback_images;
      }
    });

    if (this.data.type === MODAL_TYPE.CREATE) {
      this.data.networks.map((network) => {
        if (network.type === SOURCES.SOCIAL) {
          this.getSelectedNetworks(network);
        }
        if (network.type === SOURCES.FALCON) {
          this.getFalconIntegration(network);
        }
      });
    }

    if (this.data.source) {
      if (this.data.networks) {
        let network;

        network = this.data.networks.find((item) => item.source_type_id === this.data.source.source_type_id);
        if (this.data.source.falcon_filters) {
          this.getFalconNetworks();
          this.getFalconChannels();
          this.getFalconLabels();
          this.panelOpenState = true;
        }

        this.addSourcePayloadForm.get('sourceType').setValue(network);
        this.addSourcePayloadForm.get('sourceType').disable();
        this.tagsModel = this.data.source.tags
          .map((tag: any): ITag => ({
            ...tag,
            title: tag.tag_name || tag.tagName,
            tagName: tag.tag_name || tag.tagName,
          }))
        ;
        this.sourceType = network.type;

        this.isSourceNameShow = true;
        this.addSourcePayloadForm.get('title').setValue(this.data.source.title);

        if (this.data.source.in_sociuuhub) {
          this.isContentPublic = true;
          this.addSourcePayloadForm.get('availableAll').setValue(this.data.source.in_sociuuhub);
        }

        if (this.data.source?.falcon_filters?.falcon_visibility) {
          if (this.data.source?.falcon_filters?.falcon_visibility == 'page') {
            this.addSourcePayloadForm.get('falconVisibility').setValue(false);
          } else if (this.data.source?.falcon_filters?.falcon_visibility == 'dark') {
            this.addSourcePayloadForm.get('falconVisibility').setValue(true);
          }
        }

        this.addSourcePayloadForm.get('content_expires_after_days').setValue(this.data.source.content_expires_after_days ?? 0);
        this.addSourcePayloadForm.get('expires_at_deadline').setValue(!!this.data.source.expires_at_deadline);

        if (this.data.source.import_tags) {
          this.addSourcePayloadForm.get('importFalconTags').setValue(this.data.source.import_tags);
        }
        if (this.data.source.source_type === SOURCES.FALCON) {
          this.addSourcePayloadForm.get('type').setValue(this.data.source.source_type);
        } else {
          this.addSourcePayloadForm.get('type').setValue(network.type);
          this.addSourcePayloadForm.get('source_type_id').setValue(network.source_type_id);
        }

        if (network.id) {
          this.settingsContentHubService
            .getSocialIntegrations(network.id)
            .pipe(
              takeUntil(this.destroyed$),
              finalize(() => {
                this.loadingIndicator = false;
              })
            )
            .subscribe(({ data }) => {
              this.integrations = data.integrations;
              this.canAdd = data.can_add;
              const integration = this.integrations.find((item) => item.id === this.data.source.user_social_token_id);
              if (integration) {
                this.addSourcePayloadForm.get('user_social_token_id').setValue(integration.id);

                this.settingsContentHubService
                  .getCompanyPages(integration.user_id, network.id)
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((response) => {
                    this.addCompanyPages(response.data);
                    this.addSourcePayloadForm.get('companies').setValue(this.data.source.page_id);
                  });
              }
            });
        }

        if (network.type === SOURCES.RSS) {
          this.addSourcePayloadForm.get('rss').setValue(this.data.source.url);
        }

        if (network.type === SOURCES.EMPLY) {
          this.addSourcePayloadForm.get('emply_client_name').setValue(this.data.source.client_name);
        }

        if (network.type === SOURCES.PERSONIO) {
          this.selectNetwork(network);
          const getTrimmedUrl: string = this.getTrimmedUrlsWithoutHttps(this.data.source.job_details_url);
          this.addSourcePayloadForm.get('title').setValue(this.data.source.title);
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.ACCOUNT_NAME).setValue(this.data.source.account_name);
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.ACCOUNT_NAME).disable();
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.ACCOUNT_DOMAIN).setValue(this.data.source.account_domain);
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.ACCOUNT_DOMAIN).disable();
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).setValue(getTrimmedUrl);
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).disable();
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.AUTO_ARCHIVE).setValue(this.data.source.auto_archive);
          this.addSourcePayloadForm.get(PERSONIO_CONTROL.AUTO_UNARCHIVE).setValue(this.data.source.auto_unarchive);
          this.setSanitizedUrlForPersonio();
        }
      }
    }

    if (this.data.type === MODAL_TYPE.EDIT) {
      if (this.data?.source?.apply_tags_to_content !== undefined) {
        this.addSourcePayloadForm.get('applyTagsChangesToExistingContent')
          .setValue(!!this.data?.source?.apply_tags_to_content);
      }
      if (this.data.source?.source_type !== SOURCES.FALCON && this.data.source?.type !== SOURCES.FALCON) {
        this.addSourcePayloadForm.get('autoSendBW').setValue(this.data?.source?.auto_send_to_bw || 0);
        this.addSourcePayloadForm.get('preventDuplicateBWContent').setValue(this.data?.source?.bw_prevent_duplicates || false);
      } else {
        this.addControlIfNotPresent('bwLabelSettings', this.fb.control('prepend'));
        if (this.data?.source?.import_tags_setting) {
          if (this.addSourcePayloadForm.get('bwLabelSettings') !== undefined) {
            this.addSourcePayloadForm.get('bwLabelSettings').setValue(this.data.source.import_tags_setting);
          } else {
            this.addControlIfNotPresent('bwLabelSettings', this.fb.control(this.data.source.import_tags_setting));
          }
        }
      }
      this.addSourcePayloadForm.get('direct_share').setValue(this.data?.source?.direct_share || false);
      this.addSourcePayloadForm.get('useMLImage').setValue(this.data?.source?.force_fallback_image || false);
      this.addSourcePayloadForm.get('auto_archive_expired_content').setValue(!!this.data?.source?.auto_archived_expired_contents);
      if (this.addSourcePayloadForm.get('companies')) this.addSourcePayloadForm.get('companies').disable();
      if (this.addSourcePayloadForm.get(SOURCES.RSS)) this.addSourcePayloadForm.get(SOURCES.RSS).disable();
      if (this.addSourcePayloadForm.get('emply_client_name')) this.addSourcePayloadForm.get('emply_client_name').disable();
      if (this.data?.source?.content_expires_after_days) {
        this.addSourcePayloadForm.get('content_expires_after_days').setValue(this.data.source.content_expires_after_days);
        this.addSourcePayloadForm.get('content_expire_enabled').setValue(true);
      }
    }
  }

  public ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  public ngAfterViewChecked() {
    if (this.shouldScrollToBottom()) {
      this.matDialogContent.nativeElement.scrollTop = this.matDialogContent.nativeElement.scrollHeight;
      this.tagsUpdated = false;
    }
  }

  public addCompanyPages(companies: CompanyPage[]): void {
    companies.forEach((company: CompanyPage): void => {
      this._companyPages.set(company.id, company);
    });
  }

  public selectNetwork(network: VideoAnalyticsNetwork) {
    this.sourceType = network.type;
    this.addSourcePayloadForm.get('type').setValue(network.type);
    this.addSourcePayloadForm.get('source_type_id').setValue(network.source_type_id);
    this.addSourcePayloadForm.get('video_analytics_network_id').setValue(network.id);

    this.isSourceNameShow = true;

    this.companyIntegrations = [];

    this._companyPages.clear();

    this.getSocialIntegrations(network.id);

    if (network.type === SOURCES.SOCIAL) {
      this.addControlIfNotPresent('companies', this.fb.control('', [Validators.required]));
      this.removeControlIfExists([SOURCES.RSS, 'bwLabelSettings', 'emply_client_name', ...this.personioControlNames]);
    } else if (network.type === SOURCES.RSS) {
      this.addControlIfNotPresent('rss', this.fb.control('', [Validators.required]));
      this.removeControlIfExists(['companies', 'bwLabelSettings', 'emply_client_name', ...this.personioControlNames]);
    } else if (network.type === SOURCES.FALCON) {
      this.getFalconNetworks();
      this.getFalconChannels();
      this.getFalconLabels();
      this.removeControlIfExists([SOURCES.RSS, 'companies', 'emply_client_name', ...this.personioControlNames]);
      this.addControlIfNotPresent('bwLabelSettings', this.fb.control('prepend'));
    } else if (network.type === SOURCES.EMPLY) {
      this.addControlIfNotPresent('emply_client_name', this.fb.control('', [Validators.required]));
      this.removeControlIfExists([SOURCES.RSS, 'bwLabelSettings', 'companies', ...this.personioControlNames]);
    } else if (network.type === SOURCES.PERSONIO) {
      this.personioControls.forEach((control: IControl) => {
        this.addControlIfNotPresent(control.name, control.control);
      });
      this.personioAccValueChanges();
      this.personioURLSubscription();
      this.removeControlIfExists([SOURCES.RSS, 'bwLabelSettings', 'companies', 'emply_client_name']);
    }
  }

  public showRequiredError(control: string): boolean {
    const formControl: AbstractControl = this.addSourcePayloadForm.get(control);
    return formControl && formControl.dirty && formControl.hasError('required');
  }

  public get personioAccNameControl(): AbstractControl {
    return this.addSourcePayloadForm?.get(PERSONIO_CONTROL.ACCOUNT_NAME);
  }

  public get personioDomainControl(): AbstractControl {
    return this.addSourcePayloadForm?.get(PERSONIO_CONTROL.ACCOUNT_DOMAIN);
  }

  public get hasTagsChanged(): boolean {
    const sourceTags = this.data?.source?.tags?.map(e => e.id) || [];
    const tagsToSave = this.tagsToSave?.map(e => e.id) || [];

    return !isEqual(sortBy(sourceTags), sortBy(tagsToSave));
  }

  private manualVerification() {
    const currentValue = this.personioAccNameControl.value;
    this.personioAccNameControl.setValue(currentValue);
  }

  private getTrimmedUrlsWithoutHttps(url: string): string {
    const httpPrefix = 'http://';
    const httpsPrefix = 'https://';

    if (url.startsWith(httpPrefix)) {
      return url.slice(httpPrefix.length);
    } else if (url.startsWith(httpsPrefix)) {
      return url.slice(httpsPrefix.length);
    } else return url;
  }

  private personioAccValueChanges(): void {
    this.personioAccNameControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (val) => {
        this.personioAccValidationStatus = undefined;
        this.updateDefaultPersonioURL();
      }
    });
  }

  private personioAccSubscription(): void {
    this.$personioAccName = this.verifyPersonioClient().pipe(
      takeUntil(this.destroyed$),
      catchError(error => {
        this.handleErrors(error);
        return of(undefined);
      })
    ).subscribe({
      next: (val) => this.handleSuccess(val),
      error: (error) => console.error('Error on Account Name: ', error)
    });
  }

  private verifyPersonioClient(): Observable<any> {
    return this.integrationsService.verifyPerisoClient(
      this.personioAccNameControl.value,
      this.addSourcePayloadForm.get(PERSONIO_CONTROL.ACCOUNT_DOMAIN).value
    );
  }

  private handleErrors(error): void {
    this.isVerifyingAccName = false;
    if (typeof(error) === 'string') {
      const err = JSON.parse(error);
      if (err?.account_domain) {
        this.personioAccValidationStatus = this.personioVerificationStatuses.invalid;
      } else if (err?.account_name) {
        this.personioAccNameControl.setErrors({ 'invalid': true });
        this.personioAccValidationStatus = this.personioVerificationStatuses.invalid;
      } else if (err?.open_positions) {
        this.personioAccNameControl.setErrors({ 'no_open_positions': true })
        this.personioAccValidationStatus = this.personioVerificationStatuses.warning;
      }
    }
  }

  private handleSuccess(val): void {
    this.isVerifyingAccName = false;
    if (val === undefined) {
      if (this.data.type !== MODAL_TYPE.EDIT && this.isPersonioDefaultURLEnabled) {
        this.updateDefaultPersonioURL();
      }
      return;
    };
    if (val?.data?.x_postition_id) {
      this.currentPositionId = val.data.x_postition_id;
      this.personioAccValidationStatus = this.personioVerificationStatuses.valid;

      if (this.data.type !== MODAL_TYPE.EDIT && this.isPersonioDefaultURLEnabled) {
        this.updateDefaultPersonioURL();
      }
    }
  }

  // Call this method on blur event from the template
  onPersonioAccNameBlur(): void {
    if(this.personioAccNameControl.value) {
        this.personioAccSubscription();
        this.isVerifyingAccName = true;
    }
  }

  private personioURLSubscription(): void {
    const personioURLChanges$ = this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).valueChanges;

    this.$personioURL = personioURLChanges$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(500)
    ).subscribe((text) => {
      this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).setValue(text);
      this.isVerifyingAccURL = true;
      this.setSanitizedUrlForPersonio();
    });
  }

  public removeControlIfExists(type: string | string[]) {
    if (typeof (type) === 'string') {
      if (this.addSourcePayloadForm.contains(type)) {
        if (type === PERSONIO_CONTROL.ACCOUNT_NAME && this.$personioAccName) this.$personioAccName?.unsubscribe();
        this.addSourcePayloadForm.removeControl(type);
      }
    } else if (typeof (type) === 'object') {
      type.forEach((source: string) => {
        if (this.addSourcePayloadForm.contains(source)) {
          if (source === PERSONIO_CONTROL.ACCOUNT_NAME && this.$personioAccName) this.$personioAccName?.unsubscribe();
          this.addSourcePayloadForm.removeControl(source);
        }
      })
    }
  }

  public addControlIfNotPresent(type: string, control: AbstractControl) {
    if (typeof (type) === 'string') {
      if (!this.addSourcePayloadForm.contains(type)) {
        this.addSourcePayloadForm.addControl(type, control);
      }
    }
  }

  public shouldShowPersonioAccValidationStatus(): boolean {
    return !this.showRequiredError('personio_acc_name') && this.personioAccNameControl.value
  }

  public updatePersonioDomain(val): void {
    this.updateDefaultPersonioURL();
    this.manualVerification();
    this.personioURLSubscription();
    this.onPersonioAccNameBlur();
  }

  public updateDefaultPersonioURL(): void {
    if (this.isPersonioDefaultURLEnabled) {
      this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).setValue(this.personioDefalutURL);
      this.setSanitizedUrlForPersonio();
    }
  }

  private setSanitizedUrlForPersonio(): void {
    if (this.isPersonioDefaultURLEnabled) {
      const currentURL: string = `https://${this.currentValidPersonioUrl}`;
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(currentURL);
    } else {
      const currentUrl: string = this.getTrimmedUrlsWithoutHttps(this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).value);
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(`https://${currentUrl}`);
    }
  }

  public openURL(): void {
    let url: string;
    if (this.isPersonioDefaultURLEnabled) url = `https://${this.currentValidPersonioUrl}`;
    else url =
      `https://${
        this.addSourcePayloadForm
        .get(PERSONIO_CONTROL.PERSONIO_URL).value
        .replace('{position_id}', this.currentPositionId)
      }`
    ;
    window.open(url, '_blank');
  }

  public toggleDefaultPersonioURL(state: boolean): void {
    const control: AbstractControl = this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL);
    this.isPersonioDefaultURLEnabled = state;
    let currentCustomURL: string;
    let urlToSet: string;
    if (state) {
      this.$personioURL && this.$personioURL.unsubscribe();
      control.disable();
      currentCustomURL = control.value;
      urlToSet = this.personioDefalutURL;
      if (currentCustomURL) this.personioCustomURL = currentCustomURL;
    } else {
      this.$personioURL && this.personioURLSubscription();
      control.enable();
      urlToSet = this.personioCustomURL;
      if (control) {
        if (PERSONIO_CUSTOM_REGEX.test(control.value)) {
          control.setValidators([Validators.required, Validators.pattern(PERSONIO_CUSTOM_REGEX)]);
        }
        control.updateValueAndValidity();
      }
    }
    this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).setValue(urlToSet);
    this.setSanitizedUrlForPersonio();
  }

  public insertPositionId(): void {
    const currentURL: string = this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).value;
    this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).setValue(currentURL + '/' + this.personioPositionId);
    this.setSanitizedUrlForPersonio();
  }

  getFalconIntegration(network: VideoAnalyticsNetwork) {
    this.sourceTypeLoadingIndicator = true;
    return this.integrationsService
      .getIntegration()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.sourceTypeLoadingIndicator = false;
        })
      )
      .subscribe(({ data }) => {
        this.falconIntegrations = data;
        if (this.falconIntegrations.length === 0) {
          this.data.networks = this.data.networks.filter((item) => item.name !== network.name);
        }
      });
  }

  // not getting called from anywhere
  showSource(network: VideoAnalyticsNetwork) {
    if (network.name === 'linkedin') {
      return this.hasSourceCreateLinkedinPermission;
    } else if (network.name === 'facebook') {
      return this.hasSourceCreateFacebookPermission;
    } else if (network.name === SOURCES.RSS) {
      return this.hasSourceCreateRssPermission;
    } else if (network.name === 'brandwatch') {
      return this.hasSourceCreateFalconPermission;
    } else if (network.name === SOURCES.EMPLY) {
      return this.hasSourceCreateEmplyPermission;
    } else if (network.name === SOURCES.PERSONIO) {
      return this.hasSourceCreatePersonioPermission;
    }
  }

  getSocialIntegrations(id: number) {
    this.loadingIndicator = true;
    if (id !== null) {
      return this.settingsContentHubService
        .getSocialIntegrations(id)
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => {
            this.loadingIndicator = false;
          })
        )
        .subscribe(({ data, success }) => {
          this.integrations = data.integrations;
          if (this.integrations) {
            this.integrations.map((item) => {
              this.getCompanyPages(item);
            });
          }
        });
    }
  }

  getSelectedNetworks(network: VideoAnalyticsNetwork) {
    this.sourceTypeLoadingIndicator = true;
    if (network.id !== null) {
      return this.settingsContentHubService
        .getSocialIntegrations(network.id)
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => {
            this.sourceTypeLoadingIndicator = false;
          })
        )
        .subscribe(({ data, success }) => {
          this.integrations = data.integrations;
          if (data.integrations.length === 0) {
            this.data.networks = this.data.networks.filter((item) => item.name !== network.name);
          }
        });
    }
  }

  private getCompanyPages(selectedIntegration: Integration) {
    if (selectedIntegration) {
      this.companyLoadingIndicator = true;
      return this.settingsContentHubService
        .getCompanyPages(selectedIntegration.user_id, this.addSourcePayloadForm.get('video_analytics_network_id').value)
        .pipe(
          takeUntil(this.destroyed$),
          finalize(() => {
            this.companyLoadingIndicator = false;
          })
        )
        .subscribe(({ data, success }) => {
          data.forEach((item) => {
            item.email = selectedIntegration.email;
            this.addCompanyPages([item]);
          });
          if (data) {
            data.forEach((companyPage) => {
              this.companyIntegrations.push({
                integrationId: selectedIntegration.id,
                companyPageId: companyPage.id,
                email: selectedIntegration.email,
              });
            });
          }
        });
    }
  }

  private selectCompany() {
    this.addSourcePayloadForm
      .get('companies')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value !== 'select') {
          if (this.companyPages) {
            const selectedCompany = this.companyPages.find((page: CompanyPage) => page.id === value);
            if (selectedCompany) {
              this.addSourcePayloadForm.get('page_id').setValue(selectedCompany.id);
            }
          }
        }
      });
  }

  private getTags(keyword = '', IsNewTag = false) {
    this.tagsService
      .getTagsV2(keyword, { page: this.tagsCurrentPage })
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((response: ITagList): void=> {
        if (IsNewTag) {
          this.tags = response.data.map((item) => ({
            id: item.id,
            value: item,
            selected: true,
            name: item.tagName,
          }));
          this.selectedTagsOnEdit = [...this.selectedTagsOnEdit, ...this.tags];
        } else {
          this.tags = [...this.tags, ...response.data.map((item: ITag) => ({
            id: item.id,
            value: item,
            name: item.tagName,
            title: item.tagName,
          }))];
        }
        if (this.data.source) {
          this.selectedTagsOnEdit = this.data.source.tags
          .map((item) => ({
            id: item.id,
            name: item.tag_name || item.tagName,
            value: item,
            selected: true,
          }));
          this.tags = [...this.selectedTagsOnEdit, ...this.tags]
        }
        this.tagsLastPage = response.meta.lastPage;
      });
  }

  getClientNetworks() {
    this.settingsService
      .getClientNetworks()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.socialNetworks = response.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
            share_url: item.share_url,
            hex_color: item.hex_color,
            is_default: item.is_default,
            social_icon_url: item.social_icon_url,
            default_emv: item.default_emv,
            created_at: item.created_at,
            updated_at: item.updated_at,
            client_default: item.client_default,
            icon: item.icon,
          };
        });
        this.addSocialNetworks();
      });
  }

  private createSourcePayloadForm(): void {
    this.addSourcePayloadForm = this.fb.group({
      video_analytics_network_id: 0,
      user_social_token_id: 0,
      is_deleted: 0,
      page_id: '',
      type: '',
      source_type_id: null,
      sourceType: ['', Validators.required],
      title: ['', Validators.required],
      socialNetworks: new FormArray([]),
      // status: [this.statuses.PUBLISHED],
      availableAll: [false],
      isEmplyDate: [true],
      content_expires_after_days: [0],
      expires_at_deadline: [],
      importFalconTags: [false],
      falconVisibility: [false],
      rss: ['', Validators.required],
      emply_client_name: ['', Validators.required],
      companies: ['', Validators.required],
      preventDuplicateBWContent: [true],
      autoSendBW: [0],
      applyTagsChangesToExistingContent: [false],
      useMLImage: [false],
      direct_share: [false],
      content_expire_enabled: [false],
      auto_archive_expired_content: [false],
    });
  }



  public get hasBrandwatchIntegration(): boolean {
    return this.authService.isBrandwatchIntegrationEnabled.value;
  }

  get _socialNetworks(): FormArray {
    return this.addSourcePayloadForm.get('socialNetworks') as FormArray;
  }

  addSocialNetworks(): void {
    this.socialNetworks.forEach((item) => {
      if (item.client_default === 1 && this.data.type === MODAL_TYPE.CREATE) this._socialNetworks.push(new FormControl(true));
      else this._socialNetworks.push(new FormControl(false));
    });

    if (this.data.type === MODAL_TYPE.EDIT) {
      if (this.data.source.networks) {
        this._socialNetworks.controls.forEach((item, i) => {
          this.data.source.networks?.map((network) => {
            if (network.id === this.socialNetworks[i].id) {
              item.setValue(true);
            }
          });
        });
      } else {
        this._socialNetworks.controls.forEach((item, i) => {
          item.setValue(false);
        });
      }
    }
  }

  makeContentPublic(_event) {
    if (_event.checked) {
      this.isContentPublic = true;
    } else {
      this.isContentPublic = false;
      this.addSourcePayloadForm.get('content_expire_enabled').reset();
      this.addSourcePayloadForm.get('content_expires_after_days').reset();
      this.addSourcePayloadForm.get('content_expires_after_days').setValue(0);
    }
  }

  saveSource() {
    this.addSourceProcess = true;
    this.savingLoader = true;

    const sourcesType: string = this.addSourcePayloadForm.get('type').value;

    switch(sourcesType) {
      case SOURCES.RSS: this.requestBody = this.getRequestBodies(SOURCES.RSS); break;
      case SOURCES.EMPLY: this.requestBody = this.getRequestBodies(SOURCES.EMPLY); break;
      case SOURCES.FALCON: this.requestBody = this.getRequestBodies(SOURCES.FALCON); break;
      case SOURCES.SOCIAL: this.requestBody = this.getRequestBodies(SOURCES.SOCIAL); break;
      case SOURCES.PERSONIO: this.requestBody = this.getRequestBodies(SOURCES.PERSONIO); break;
      default: this.requestBody = null; break;
    }

    if (!this.requestBody) return;

    if (this.data.type === MODAL_TYPE.CREATE) {
      if (sourcesType === SOURCES.PERSONIO) this.addPersonioDetails();
      else this.addOtherSourceDetails();
    }

    if (this.data.type === MODAL_TYPE.EDIT) {
      if (this.hasTagsChanged) {
        this.requestBody = {
          ...this.requestBody,
          apply_tags_to_content: !!this.addSourcePayloadForm.get('applyTagsChangesToExistingContent').value,
        }
      }
      if (sourcesType === SOURCES.PERSONIO) this.updatePersonioSource();
      else this.updateOtherSources();
    }
  }

  public addTag(tag: ITag[]): void {
    if (!tag) return;
    this.tagsModel = tag;
    this.tagsToSave = tag;
  }

  private getRequestBodies(request: SOURCES): any {
    let rssBody = null;
    let emplyBody = null;
    let falconBody = null;
    let requestedData = null;
    let socialSourceBody = null;
    let personioBody: IPersonio = null;

    const selectedNetworkIds = this.addSourcePayloadForm
      .get('socialNetworks')
      .value.map((checked, i) => {
        return checked ? this.socialNetworks[i].id : null;
      })
      .filter((id) => id !== null);

    if (this.addSourcePayloadForm.get('availableAll').value) {
      if (!selectedNetworkIds.length) {
        this.openCustomSnackBar('Active networks is required when you make content available in SociuuHub', 'warning');
        return;
      }
    }

    if (request === SOURCES.SOCIAL) {
      socialSourceBody = {
        user_social_token_id: this.data.type === MODAL_TYPE.EDIT
          ? this.addSourcePayloadForm.get('user_social_token_id').value
          : this.companyIntegrations
            .filter((item) => {
              return (
                item.companyPageId == this.addSourcePayloadForm.get('page_id').value &&
                item.email == this.selectedCompanyPage.email
              );
            })
            .map((item) => item.integrationId)[0]
        ,
        type: this.addSourcePayloadForm.get('type').value,
        source_type_id: this.addSourcePayloadForm.get('source_type_id').value,
        tags: this.tagsToSave.map((item): number => item.id),
        title: this.addSourcePayloadForm.get('title').value,
        page_id: this.addSourcePayloadForm.get('page_id').value,
        is_deleted: 0,
        network_ids: selectedNetworkIds,
        available_all: this.addSourcePayloadForm.get('availableAll').value ? 1 : 0,
        ...((this.addSourcePayloadForm.get('content_expires_after_days').value > 0 && this.contentExpireEnabled) && {
          content_expires_after_days: this.addSourcePayloadForm.get('content_expires_after_days').value,
        }),
      };
    }

    if (request === SOURCES.RSS) {
      rssBody = {
        type: this.addSourcePayloadForm.getRawValue().type,
        source_type_id: this.addSourcePayloadForm.getRawValue().source_type_id,
        rss_url: this.addSourcePayloadForm.getRawValue().rss,
        title: this.addSourcePayloadForm.getRawValue().title,
        tags: this.tagsToSave.map((item) => item.id),
        network_ids: selectedNetworkIds,
        available_all: this.addSourcePayloadForm.get('availableAll').value ? 1 : 0,
        ...(this.addSourcePayloadForm.get('content_expires_after_days').value > 0 && {
          content_expires_after_days: this.addSourcePayloadForm.get('content_expires_after_days').value,
        }),
      };
    }

    if (request === SOURCES.FALCON) {
      falconBody = {
        type: this.addSourcePayloadForm.getRawValue().type,
        source_type_id: this.addSourcePayloadForm.getRawValue().source_type_id,
        title: this.addSourcePayloadForm.getRawValue().title,
        tags: this.tagsToSave.map((item) => {
          return item.id;
        }),
        import_falcon_tags: this.addSourcePayloadForm.get('importFalconTags').value,
        network_ids: selectedNetworkIds,
        available_all: this.addSourcePayloadForm.get('availableAll').value ? 1 : 0,
        ...((this.addSourcePayloadForm.get('content_expires_after_days').value > 0 && this.contentExpireEnabled) && {
          content_expires_after_days: this.addSourcePayloadForm.get('content_expires_after_days').value,
        }),
        ...(this.falconNetworksToSave.length && { falcon_networks: this.falconNetworksToSave }),
        ...(this.falconChannelsToSave.length && { falcon_channels: this.falconChannelsToSave }),
        ...(this.falconLabelsToSave.length && { falcon_labels: this.falconLabelsToSave }),
        // ...(this.statusListToSave.length && { falcon_statuses: this.statusListToSave }),
        falcon_visibility: this.addSourcePayloadForm.get('falconVisibility').value ? 'dark' : 'page',
        placements: this.addSourcePayloadForm.get('falconVisibility').value ? 'FEED,STORY,REEL' : 'FEED,STORY,REEL',
        ...(this.addSourcePayloadForm.get('importFalconTags')?.value) && {
          import_tags_setting: this.addSourcePayloadForm.get('bwLabelSettings')?.value,
        }
      };
    }

    const expiresAtDeadline: boolean = this.addSourcePayloadForm.get('expires_at_deadline').value;
    const availableAll: boolean = this.addSourcePayloadForm.get('availableAll').value;
    const contentExpireDate: number = this.addSourcePayloadForm.get('content_expires_after_days').value;

    const isNoOfDays: boolean =
      !this.addSourcePayloadForm.get('expires_at_deadline').value &&
      this.addSourcePayloadForm.get('content_expires_after_days').value > 0;

    if (request === SOURCES.EMPLY) {
      emplyBody = {
        type: this.addSourcePayloadForm.getRawValue().type,
        source_type_id: this.addSourcePayloadForm.getRawValue().source_type_id,
        client_name: this.addSourcePayloadForm.getRawValue().emply_client_name,
        title: this.addSourcePayloadForm.getRawValue().title,
        tags: this.tagsToSave.map((item) => item.id),
        network_ids: selectedNetworkIds,
        available_all: availableAll ? 1 : 0,
        ...(availableAll && { expires_at_deadline: expiresAtDeadline }),
        ...((isNoOfDays && this.contentExpireEnabled) && { content_expires_after_days: contentExpireDate }),
      };
    }

    if (request === SOURCES.PERSONIO) {
      if (this.personioAccNameControl.value) this.personioURLSubscription();
      let contentExpireAfterDays: number = this.addSourcePayloadForm.get('content_expires_after_days').value;
      const jobDetailsUrl: string = `https://${this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).value}`
      personioBody = {
        ...selectedNetworkIds?.length && { network_ids: selectedNetworkIds },
        available_all: availableAll ? 1 : 0,
        ...this.tagsToSave?.length && { tags: this.tagsToSave.map((item) => item.id) },
        type: this.addSourcePayloadForm.getRawValue().type,
        account_name: this.personioAccNameControl.value,
        account_domain: this.personioDomainControl.value,
        title: this.addSourcePayloadForm.get('title').value,
        auto_archive: this.addSourcePayloadForm.get(PERSONIO_CONTROL.AUTO_ARCHIVE).value,
        job_details_url: jobDetailsUrl,
        auto_unarchive: this.addSourcePayloadForm.get(PERSONIO_CONTROL.AUTO_UNARCHIVE).value,
        source_type_id: this.addSourcePayloadForm.getRawValue().source_type_id,
      };
      if (contentExpireAfterDays && this.contentExpireEnabled) {
        personioBody = {
          ...personioBody,
          content_expires_after_days: contentExpireAfterDays,
        }
      }
    }

    switch (request) {
      case SOURCES.RSS: requestedData = rssBody; break;
      case SOURCES.EMPLY: requestedData = emplyBody; break;
      case SOURCES.FALCON: requestedData = falconBody; break;
      case SOURCES.PERSONIO: requestedData = personioBody; break;
      case SOURCES.SOCIAL: requestedData = socialSourceBody; break;
      default: requestedData = null; break;
    }
    if (request !== SOURCES.FALCON && requestedData) {
      requestedData = {
        auto_send_to_bw: this.addSourcePayloadForm.get('autoSendBW').value,
        bw_prevent_duplicates: this.addSourcePayloadForm.get('preventDuplicateBWContent').value,
        ...requestedData,
      };
    }
    if (requestedData) {
      requestedData = {
        direct_share: !!this.addSourcePayloadForm.get('direct_share').value,
        force_fallback_image: !!this.addSourcePayloadForm.get('useMLImage').value,
        auto_archived_expired_contents: !!this.addSourcePayloadForm.get('auto_archive_expired_content').value,
        ...requestedData,
      }
    }
    return requestedData;
  }

  private updatePersonioSource() {
    let networkIds: number[] = [];

    if (this.requestBody.network_ids) networkIds = this.requestBody.network_ids;
    let contentExpireAfterDaysControlValue: number = this.addSourcePayloadForm.get('content_expires_after_days').value;

    let updateRequest: IPersonioUpdate = {
      network_ids: networkIds,
      tags: this.requestBody.tags || [],
      source_id: this.data.source.id,
      available_all: this.requestBody.available_all,
      auto_archive: this.requestBody.auto_archive,
      auto_unarchive: this.requestBody.auto_unarchive,
      title: this.requestBody.title,
      account_domain: this.requestBody.account_domain,
    }

    if (this.requestBody.available_all === 1 && contentExpireAfterDaysControlValue && this.contentExpireEnabled) {
      updateRequest = {
        ...updateRequest,
        content_expires_after_days: contentExpireAfterDaysControlValue,
      }
    }

    this.settingsContentHubService
      .updatePersonioSource(updateRequest)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.addSourceProcess = false;
          this.savingLoader = false;
        })
      )
      .subscribe((response) => {
        if (response.data.source && response.data.source_data) {
          this.companyIntegrations = [];
          this.openCustomSnackBar('Source updated successfully.', 'success');
          this.dialogRef.close(response.data.source_data);
        } else {
          this.openCustomSnackBar(response.messages[0], 'danger');
        }
      });
  }

  private updateOtherSources() {
    this.settingsContentHubService
      .updateSource(this.data.source.id, this.requestBody)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.savingLoader = false;
          this.addSourceProcess = false;
        })
      )
      .subscribe((response) => {
        if (response.success === true) {
          this.companyIntegrations = [];
          this.dialogRef.close(response);
          this.openCustomSnackBar('Source updated successfully.', 'success');
        } else {
          this.openCustomSnackBar(response.messages[0], 'danger');
        }
      });
  }

  private addPersonioDetails() {
    this.settingsContentHubService
      .createPersonioSource(this.requestBody)
      .pipe(
        takeUntil(this.destroyed$),
        catchError((error) => {
          if (
            !this.shouldAddShowInsertPositionID &&
            this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).valid &&
            !this.isPersonioDefaultURLEnabled
          ) {
            if (PERSONIO_CUSTOM_REGEX.test(this.addSourcePayloadForm.get(PERSONIO_CONTROL.PERSONIO_URL).value)) {
              this.personioURLValidationStatus = personioVerificationStates.invalid;
            }
          }
          console.error(error);
          return of({});
        }),
        finalize(() => {
          this.addSourceProcess = false;
          this.savingLoader = false;
        })
      )
      .subscribe({
        next: (response: any) => {
        if (response?.data?.source) {
          this.companyIntegrations = [];
          this.personioURLValidationStatus = personioVerificationStates.valid;
          this.openCustomSnackBar('Source added successfully.', 'success');
          this.dialogRef.close(response);
        }
      }
    });
  }

  private addOtherSourceDetails() {
    this.settingsContentHubService
      .creteSource(this.requestBody)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.addSourceProcess = false;
          this.savingLoader = false;
        })
      )
      .subscribe((response) => {
        if (response.success === true) {
          this.companyIntegrations = [];
          this.openCustomSnackBar('Source added successfully.', 'success');
          this.dialogRef.close(response);
        } else {
          this.openCustomSnackBar(response.messages[0], 'danger');
        }
      });
  }

  private openCustomSnackBar(message: string, icon: string): void {
    this.snackBar.openFromComponent(IconSnackBarComponent, {
      data: {
        message: message,
        icon: icon,
      },
    });
  }

  dismiss() {
    this.dialogRef.close(null);
  }

  selectedItems(selectedTags) {
    this.tagsToSave = selectedTags.selected;
    this.tagsUpdated = true;
  }

  selectedFalconNetworks(selectedNetworks) {
    this.falconNetworksToSave = selectedNetworks.selected?.map((item) => item.id);
  }

  selectedFalconChannels(selectedChannels) {
    this.falconChannelsToSave = selectedChannels.selected?.map((item) => item.id);
  }

  selectedFalconLabels(selectedLabels) {
    this.falconLabelsToSave = selectedLabels.selected?.map((item) => item.id);
  }

  searchItems(keyword: string): void {
    this.tagsCurrentPage = 1;
    this.getTags(keyword);
  }

  searchFalconLabels(keyword: string): void {
    this.labelKeyword = keyword;
    if (keyword) {
      this.settingsContentHubService
        .searchFalconLabels(keyword)
        .pipe(
          takeUntil(this.destroyed$),
          map((response) => response.data)
        )
        .subscribe((res) => {
          this.falconLabels = Object.entries(res)
            .map(([id, name]) => ({ id, name }))
            .map((res) => {
              return {
                id: res.id,
                name: res.name,
                value: res,
              };
            });
        });
    } else {
      this.getFalconLabels();
    }
  }

  createNewTag(tagName: string) {
    this.tagsService
      .createTag(tagName)
      .pipe()
      .subscribe((response) => {
        this.openCustomSnackBar('Tag added successfully.', 'success');
        this.getTags(tagName, true);
      });
  }

  public loadMore(keyword: string): void {
    this.tagsCurrentPage++;
    if (this.tagsLastPage >= this.tagsCurrentPage) this.getTags(keyword, false);
  }

  changeCompanyPage(event, selectedCompanyPage) {
    if (event.source._selected) {
      this.selectedCompanyPage = selectedCompanyPage;
    }
  }

  getFalconNetworks() {
    this.falconNetworksLoadingIndicator = true;
    this.settingsContentHubService
      .getFalconNetworks()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.falconNetworksLoadingIndicator = false;
        })
      )
      .subscribe((res) => {
        if (this.data.type === MODAL_TYPE.CREATE) {
          this.falconNetworks = Object.entries(res.data)
            .map(([id, name]) => ({ id, name }))
            .map((network) => {
              return {
                id: network.id,
                name: network.name,
                value: network,
              };
            });
          this.selectedFalconNetworksOnEdit = this.falconNetworks?.map((network) => {
            return {
              id: network.id,
              name: network.name,
              value: network,
              selected: true,
            };
          });
        }

        if (this.data.type === MODAL_TYPE.EDIT) {
          this.falconNetworks = Object.entries(res.data)
            .map(([id, name]) => ({ id, name }))
            .map((network) => {
              return {
                id: network.id,
                name: network.name,
                value: network,
              };
            });

          if (this.data.source) {
            const selectedFalconNetworks = this.falconNetworks?.filter((network) => {
              return this.data.source.falcon_filters?.falcon_networks?.includes(network.id);
            });

            this.selectedFalconNetworksOnEdit = selectedFalconNetworks?.map((network) => {
              return {
                id: network.id,
                name: network.name,
                value: network,
                selected: true,
              };
            });
          }
        }
      });
  }

  getFalconChannels() {
    this.falconChannelsLoadingIndicator = true;
    this.settingsContentHubService
      .getFalconChannels()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.falconChannelsLoadingIndicator = false)
      )
      .subscribe((res) => {
        this.falconChannels = res.data.map((res) => {
          return {
            id: res.id,
            name: res.name + ' ' + '(' + res.network + ')',
            value: res,
          };
        });

        if (this.data.source) {
          const selectedFalconChannels = this.falconChannels?.filter((channel) => {
            return this.data.source.falcon_filters?.falcon_channels?.includes(channel.id);
          });

          this.selectedFalconChannelsOnEdit = selectedFalconChannels?.map((channel) => {
            return {
              id: channel.id,
              name: channel.name + ' ' + '(' + channel.network + ')',
              value: channel,
              selected: true,
            };
          });
        }
      });
  }

  getFalconLabels() {
    let obj = [];
    const matchingKeyValuePairs = this.data.source?.falcon_filters?.falcon_labels?.map((x: string) => [x, x]);
    if (matchingKeyValuePairs) obj = Object.fromEntries(matchingKeyValuePairs);
    this.falconLabelsLoadingIndicator = true;
    this.settingsContentHubService
      .getFalconLabels()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.falconLabelsLoadingIndicator = false)
      )
      .subscribe((res: any) => {
      this.falconLabels = Object.entries({...obj, ...res.data})
        .map(([id, name]) => ({ id, name }))
        .map((res) => {
          return {
            id: res.id,
            value: res,
            name: res.name,
          };
        });
        if (this.data.source) {
          const selectedFalconLabels = this.falconLabels?.filter((label) => {
            return this.data.source.falcon_filters?.falcon_labels?.includes(label.id);
          });

          this.selectedFalconLabelsOnEdit = selectedFalconLabels?.map((label) => {
            return {
              id: label.id,
              name: label.name,
              value: label,
              selected: true,
            };
          });
        }
      });
  }
}
