import { Observable, map } from 'rxjs';
import { environment } from '@utility/app.util';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IActimoDistributionDeletion } from '../distribution-channels/delete-distribution-dialog/delete-distribution-channel-dialog.component';

import {
  LogoUploadDto,
  FrequencyCapDto,
  SiteSettingsDto,
  CreateChannelDto,
  DeleteChannelDto,
  UpdateChannelDto,
  DefaultResponseDto,
  NetworksResponseDto,
  CreateChannelPayload,
  DistributionTypeDataDto,
  DistributionChannelsResponseDto,
} from '@sociuu/interfaces';
import { ISettings, ISettingsServer, SETTINGS_KEYS } from '@lib/settings.interface';

export interface ICompanyInfo {
  name: string;
  logo: string;
}

export interface IDeleteChannel extends IActimoDistributionDeletion {
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  public getSettingsV2(): Observable<ISettings[]> {
    return this.http.get<{ data: ISettingsServer[] }>(`${environment.apiUrl}/v2/settings`)
      .pipe(
        map((response: { data: ISettingsServer[] }): ISettingsServer[] => response.data),
        map((response: ISettingsServer[]): ISettings[] => 
          response.map((setting: ISettingsServer): ISettings => this.transformSettingsV2(setting)
        ),
      )
    );
  }

  public getSettingsByKeyV2(key: SETTINGS_KEYS): Observable<ISettings> {
    return this.http.get<{ data: ISettingsServer }>(`${environment.apiUrl}/v2/settings/${key}`)
      .pipe(
        map((response: { data: ISettingsServer }): ISettingsServer => response.data),
        map((response: ISettingsServer): ISettings => this.transformSettingsV2(response)),
      );
  }

  public updateSettingsV2(key: SETTINGS_KEYS, value: any): Observable<any> {
    return this.http.patch<{ data: ISettingsServer[] }>(`${environment.apiUrl}/v2/settings/${key}`, { value })
      .pipe(
        map((response: { data: any }): ISettingsServer[] => response.data),
      );
  }

  getSettings(): Observable<SiteSettingsDto> {
    return this.http.get<SiteSettingsDto>(`${environment.apiUrl}/client/settings/fetch`);
  }

  saveSettings(data): Observable<any> {
    return this.http.post<Observable<any>>(`${environment.apiUrl}/client/settings/save`, data);
  }

  getClientFrequencyCap(): Observable<FrequencyCapDto> {
    return this.http.get<FrequencyCapDto>(`${environment.apiUrl}/settings/getClientFrequencyCap`);
  }

  setClientFrequencyCap(data): Observable<DefaultResponseDto> {
    return this.http.put<DefaultResponseDto>(`${environment.apiUrl}/settings/setClientFrequencyCap`, data);
  }

  getNotificationSettings(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.apiUrl}/settings/getNotificationSettings`);
  }

  setNotificationSettings(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/settings/setAdminNotifications`, data);
  }

  uploadLogoToAWS(fileToUpload: File, type: string): Observable<LogoUploadDto> {
    const formData: FormData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    formData.append('type', type);
    return this.http.post<LogoUploadDto>(`${environment.apiUrl}/file/upload`, formData);
  }

  getClientNetworks(): Observable<NetworksResponseDto> {
    return this.http.get<NetworksResponseDto>(`${environment.apiUrl}/client/getNetworks`);
  }

  setClientDefaultNetworks(network_ids: number[]): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${environment.apiUrl}/settings/defaults/client-networks`, {
      network_ids,
    });
  }

  /*  removeLogo(image: string): Observable<DefaultResponseDto> {
    const data = { 'image_name': image };
    return this.http.post<DefaultResponseDto>(`${environment.apiUrl}/file/removeImage`, data);
  }*/

  updateSociuuHubLinkSettings(payload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/settings/updateSociuuhubLinkSetting`, payload);
  }

  getSociuuhubLinkSetting(): Observable<any> {
    return this.http.get<Observable<any>>(`${environment.apiUrl}/settings/getSociuuhubLinkSetting`);
  }

  getDistributionChannels(): Observable<DistributionChannelsResponseDto> {
    return this.http.get<DistributionChannelsResponseDto>(`${environment.apiUrl}/distributions/all`);
  }

  getDistributionTypes(isEdit: boolean): Observable<DistributionTypeDataDto> {
    if (isEdit) {
      return this.http.get<DistributionTypeDataDto>(`${environment.apiUrl}/distributions/types?mode=edit`);
    } else {
      return this.http.get<DistributionTypeDataDto>(`${environment.apiUrl}/distributions/types`);
    }
  }

  createChannel(data: CreateChannelPayload): Observable<CreateChannelDto> {
    return this.http.post<CreateChannelDto>(`${environment.apiUrl}/distributions/create`, data);
  }

  changeChannelStatus(clientDistributionId: number, channelStatus: number): Observable<CreateChannelDto> {
    const status = !channelStatus ? 'enable' : 'disable';
    return this.http.put<CreateChannelDto>(`${environment.apiUrl}/distributions/${clientDistributionId}/${status}`, {});
  }

  updateChannel(
    clientDistributionId: number,
    data: { name: string; is_enabled: boolean; id?: number; categories?: number[]; distribution_channel?: number }
  ): Observable<UpdateChannelDto> {
    return this.http.put<UpdateChannelDto>(`${environment.apiUrl}/distributions/${clientDistributionId}`, data);
  }

  public getMLImagesCount(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/v2/counts`);
  }

  public deleteChannel(data: IDeleteChannel): Observable<DeleteChannelDto> {
    const { id, isActimoDistribution, shouldDeleteActimoUsers, shouldDeleteUsergroups }: IDeleteChannel = data;

    if (isActimoDistribution) {
      const deleteActimoUsers: number = shouldDeleteActimoUsers ? 1 : 0;
      const deleteActimoUsergroups: number = shouldDeleteUsergroups ? 1 : 0;

      return this.http.delete<DeleteChannelDto>(
        `${environment.apiUrl}/distributions/${id}?delete_users=${deleteActimoUsers}&delete_usergroups=${deleteActimoUsergroups}`
      );
    } else {
      return this.http.delete<DeleteChannelDto>(`${environment.apiUrl}/distributions/${id}`);
    }
  }

  private transformSettingsV2(setting: ISettingsServer): ISettings {
    return ({
      key: setting.key,
      value: setting.value,
      dataType: setting.data_type,
      isLocked: setting.is_locked,
      defaultValue: setting.default_value,
      updatedAt: setting.updated_at,
      isDefault: setting.is_default,
    });
  }
}
