import { isNil } from 'lodash';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { isArray, isEmpty, isFunction, get } from 'lodash';
import { environment as env } from '../../assets/js/environments';

import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { IContentMedia } from '@lib/create-native-post.interface';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export enum MODAL_WIDTH {
  XL = 'min(1152px, 86%)',
  LG = 'min(768px, 86%)',
  MD = 'min(525px, 86%)',
  SM = 'min(425px, 86%)',
}

export enum MODAL_TYPE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum MODE {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

export enum INTEGRATIONS {
  ACTIMO = 'actimo',
  BRANDWATCH = 'brandwatch',
}

export type ModalType = typeof MODAL_TYPE;

export const MAX_NUMBER_OF_ROWS: number = 10001;
export const SENDOUT_SETTINGS_TEXT: string = 'Calculating last Send-out ...';
export const IMAGE_MIME_TYPE: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
export const IMAGE_MIME_TYPE_ACCEPT: string = IMAGE_MIME_TYPE.join(', ');
export const VIDEO_MIME_TYPE: string[] = ['video/mp4'];
export const VIDEO_MIME_TYPE_ACCEPT: string = VIDEO_MIME_TYPE.join(', ');
export const FILE_MIME_TYPE: string[] = [
  // 'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
  // 'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const FILE_MIME_TYPE_ACCEPT: string = FILE_MIME_TYPE.join(', ');

export enum FILE_UPLOAD_STATE {
  PRE_UPLOAD = 'pre-upload',
  IN_PROGRESS = 'in-progress',
  SUCCESS = 'success',
  ABORTED = 'aborted',
  ERROR = 'error',
}

export enum TYPE_ID {
  PHOTO = 100,
  VIDEO = 200,
  TEXT = 300,
  DOCUMENT = 400,
  JOB = 500,
  LINK = 600,
  OTHER = 700,
}

export enum DOCUMENT_TYPE {
  // doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pdf = 'application/pdf',
}

export enum IMAGE_TYPE {
  png = 'image/png',
  jpg = 'image/jpg',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
}

export enum IMAGE_TYPE_EXTENSIONS {
  'image/png' = '.png',
  'image/jpg' = '.jpeg',
  'image/gif' = '.gif',
  'image/jpeg' = '.jpeg',
}

export enum VIDEO_TYPE {
  mp4 = 'video/mp4',
}

export enum VIDEO_TYPE_EXTENSIONS {
  'video/mp4' = '.mp4',
}

export interface IStateProperties {
  icon?: string;
  class?: string;
  color?: string;
  fileName?: string;
}

export interface IPaginationServer {
  to: number;
  from: number;
  total: number;
  per_page: number;
  last_page: number;
  current_page: number;
}

export interface IPagination {
  to: number;
  from: number;
  total: number;
  perPage: number;
  lastPage: number;
  currentPage: number;
}

export const FILE_STATE_ICON_MAPPING: Record<FILE_UPLOAD_STATE, IStateProperties> = {
  [FILE_UPLOAD_STATE.PRE_UPLOAD]: {
    icon: 'mat_outline:cloud',
    color: '#A4ABB6',
    class: 'pre-upload bg-primary-50',
    fileName: 'cloud',
  },
  [FILE_UPLOAD_STATE.IN_PROGRESS]: {
    icon: 'mat_outline:cloud_upload',
    color: '#C9E1EF',
    class: 'in-progress',
    fileName: 'inProgress',
  },
  [FILE_UPLOAD_STATE.SUCCESS]: { icon: 'mat_outline:cloud_done', color: '#5B751D', class: 'success', fileName: 'success' },
  [FILE_UPLOAD_STATE.ABORTED]: { icon: '', color: '#FFB302', class: 'abort', fileName: 'abort' },
  [FILE_UPLOAD_STATE.ERROR]: { icon: 'mat_outline:error_outline', color: '#FF3838', class: 'error', fileName: 'error' },
};

export const isEmptyArray: (array: any) => boolean = (array: any): boolean => {
  return isNil(array) || (isArray(array) && isEmpty(array));
};

export let environment: any = env;
export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const DATE_FORMAT_FOR_PIPE: string = 'yyyy-MM-dd';
export const DATE_FORMAT_WITHOUT_SECONDS: string = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_WITH_SECONDS: string = 'YYYY-MM-DD HH:mm:ss';

export function toParagraphCase(text: string): string {
  // Regular expression to match sentence enders (., !, ?) followed by a space or end of string
  const sentenceEndersRegEx = /([.!?]\s*|\s+|^)/g;

  let result = text.toLowerCase().replace(sentenceEndersRegEx, (match) => {
    // Trim the match to handle cases where multiple spaces follow a sentence ender
    // and ensure only the first character of the next sentence is capitalized.
    return match.toUpperCase();
  });

  // Ensure the very first character of the text is capitalized
  if (result.length > 0) {
    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
}

export const SUPPORTED_LANGUAGES: { id: string; label: string }[] = [
  {
    id: 'en-US',
    label: 'English USA',
  },
  {
    id: 'tr-TR',
    label: 'Turkish',
  },
  {
    id: 'nl-NL',
    label: 'Dutch',
  },
  {
    id: 'de-DE',
    label: 'German',
  },
  {
    id: 'da-DK',
    label: 'Danish',
  },
  {
    id: 'en-UK',
    label: 'English',
  },
  {
    id: 'es-ES',
    label: 'Spanish',
  },
  {
    id: 'fr-FR',
    label: 'French',
  },
  {
    id: 'fi-FI',
    label: 'Finnish',
  },
  {
    id: 'nb-NO',
    label: 'Norwegian',
  },
  {
    id: 'sv-SE',
    label: 'Swedish',
  },
];

export function initQueryParams(router: Router): any {
  const state: RouterStateSnapshot = router.routerState.snapshot;
  const tree: UrlTree = router.parseUrl(state.url);
  delete tree.queryParams['magic-token'];
  const returnUrl: string = tree.toString();
  return state.url === '/dashboard' ? {} : { queryParams: { returnUrl: returnUrl } };
}

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  ;
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function fileToArrayBuffer(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Handle successful reading
    reader.onload = () => {
      resolve(reader.result); // `reader.result` will be an ArrayBuffer
    };

    // Handle errors
    reader.onerror = (error) => {
      reject(error);
    };

    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  });
}

export async function convertPDFURLToImages(media: IContentMedia): Promise<string[]> {
  if (!media) return null;
  const fileName: string = 'application.pdf';
  const url: string = `${environment.apiUrl}/v2/media-library/media/preview/${media.id}`;
  const arrayBuffer: ArrayBuffer = await urltoFile(url, fileName, media.mimeType) as ArrayBuffer;

  return await convertPdfToBase64Images(arrayBuffer);
}

export async function urltoFile(url, filename, mimeType): Promise<ArrayBuffer | File> {
  if (url.startsWith('data:')) {
    var arr = url.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    ;
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }

  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json, text/plain, */*',
  };
  return fetch(url, { headers: headers })
    .then((res) => res.arrayBuffer())
  ;
}
  
export async function convertPdfToBase64Images(pdfData: ArrayBuffer): Promise<string[]> {
  let base64Images: string[] = [];
  const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const viewport = page.getViewport({ scale: 2 });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    await page.render({ canvasContext: context, viewport }).promise;

    const base64Image = canvas.toDataURL('image/png');
    base64Images.push(base64Image);
  }

  return base64Images;
}

export function getDomainFromUrl(url: string): string {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname; // Returns the domain
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

export function getValidationErrorMessage(err: any): string {
  let dataMessage: string = '';
  if (err?.data) {
    const key: string = Object.keys(err.data)?.[0];
    dataMessage = isArray(err.data[key]) ? err.data[key][0] : err.data[key];
  }
  const message: string = dataMessage || err.message || err.messages?.[0] || 'Something went wrong, please try again.';
  return message;
}

export function canUseToString(value) {
  return isFunction(get(value, 'toString'));
}

export function transformPagination(meta: IPaginationServer): IPagination {
  return {
    to: meta.to,
    from: meta.from,
    total: meta.total,
    perPage: meta.per_page,
    lastPage: meta.last_page,
    currentPage: meta.current_page,
  };
}
