<div class="modal relative">
  <fuse-alert [appearance]="'soft'" [showIcon]="false" [type]="'info'" class="flex items-between justify-center app-banner">
    <p class="mb-0">
      <span>Linkedin is having issues with their sharing window at the moment.&nbsp;</span>
      <span>If your users share via Linkedin social connect, this can be avoided.&nbsp;</span>
      <span>See how <a href="https://www.sociuu.how/status" target="_blank" class="underline">here</a>&nbsp;</span>
    </p>
  </fuse-alert>
  <button class="text-white absolute z-index-1 top-4 right-4" (click)="close()">
    <mat-icon class="text-white" [svgIcon]="'heroicons_outline:x'"></mat-icon>
  </button>
</div>

<ng-template #star>
  <svg class="w-8 h-8" width="32px" height="32px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128" aria-hidden="true" role="img" class="iconify iconify--noto"
    preserveAspectRatio="xMidYMid meet">
    <path
      d="M68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01z"
      fill="#fdd835" />
    <path
      d="M67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13z"
      fill="#ffff8d" />
    <path
      d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97z"
      fill="#f4b400" />
  </svg>
</ng-template>