<mat-dialog-content #matDialogContent>
  <ng-container>
    <p class="mb-4 w-full text-3xl" *ngIf="data.type === 'CREATE'">Add new source</p>
    <p class="mb-4 w-full text-3xl" *ngIf="data.type === 'EDIT'">Update Source</p>
  </ng-container>
  <div class="modal-body">
    <form [formGroup]="addSourcePayloadForm" class="w-full">
      <div class="row mt-0">
        <div class="mb-2">
          <mat-form-field appearance="outline" class="width-100 no-padding-bottom">
            <mat-label>Source type</mat-label>
            <mat-select formControlName="sourceType" (selectionChange)="selectNetwork($event.value)">
              <ng-container *ngFor="let network of data.networks">
                <mat-option *ngIf="showSource(network)" [value]="network">
                  {{ network.name | titlecase }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="sourceTypeLoadingIndicator">
            <div class="flex">
              <mat-spinner class="mr-2 mt-1" diameter="20"></mat-spinner> Loading
            </div>
          </ng-container>
        </div>
        <div *ngIf="isSourceNameShow" class="mb-2">
          <mat-form-field appearance="outline" class="width-100 no-padding-bottom">
            <mat-label>Name</mat-label>
            <input matInput formControlName="title" placeholder="Name" />
          </mat-form-field>
        </div>
        <div *ngIf="sourceType === 'social'" class="mb-2">
          <ng-container>
            <mat-form-field appearance="outline" class="width-100 no-padding-bottom">
              <mat-label>Company pages</mat-label>
              <mat-select formControlName="companies">
                <mat-option (onSelectionChange)="changeCompanyPage($event, company)"
                  *ngFor="let company of companyPages" [value]="company?.id">
                  {{ company.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <mat-spinner class="inline-block" style="display: inline-block" *ngIf="companyLoadingIndicator"
            diameter="20">Loading</mat-spinner>
        </div>

        <div *ngIf="sourceType === 'rss'" class="mb-2">
          <mat-form-field appearance="outline" class="width-100 no-padding-bottom">
            <mat-label>RSS feed URL</mat-label>
            <input matInput formControlName="rss" placeholder="RSS feed URL" />
          </mat-form-field>
        </div>

        <ng-container *ngIf="sourceType === 'falcon'">
          <div class="mb-2">
            <mat-slide-toggle formControlName="importFalconTags">Import Brandwatch labels as tags</mat-slide-toggle>

            <div *ngIf="addSourcePayloadForm?.get('importFalconTags')?.value" class="flex my-4 flex-col text-base">
              <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header class="bg-white panel-height">
                    <mat-panel-title class="font-bold text-sm">Import Label Settings</mat-panel-title>
                  </mat-expansion-panel-header>

                <mat-expansion-panel-body class="pb-0">
                  <mat-radio-group class="flex flex-col" formControlName="bwLabelSettings">
                    <mat-radio-button class="radio-btn-ext" [value]="'prepend'">
                      Prepend Tags from Brandwatch with "f:"
                      <help [tooltip]="tagsFromBrandwatchTooltipText" [iconSize]="' sm'" [style.color]="'grey'"></help>
                    </mat-radio-button>
                    <mat-radio-button class="radio-btn-ext" [value]="'merge'">
                      Merge with Existing Tags
                      <help [tooltip]='existingTagsTooltipText' [iconSize]="' sm'" [style.color]="'grey'"></help>
                    </mat-radio-button>
                  </mat-radio-group>
                </mat-expansion-panel-body>
              </mat-expansion-panel>
            </div>

          </div>

          <mat-expansion-panel [expanded]="panelOpenState">
            <mat-expansion-panel-header class="bg-white">
              <mat-panel-title class="font-bold text-sm"> Content filters </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="mb-2">
              <sociuu-dropdown [list]="falconNetworks" [searchable]="false"
                [selectedList]="selectedFalconNetworksOnEdit" [isCreateNew]="false" [isChips]="false" label="networks"
                [isCustomTrigger]="true" searchPlaceholder="Select networks *"
                (selectedItems)="selectedFalconNetworks($event)" [customTriggerText]="'network(s) applied'">
              </sociuu-dropdown>
              <mat-spinner *ngIf="falconNetworksLoadingIndicator" diameter="20">Loading</mat-spinner>
            </div>

            <div class="mb-2">
              <sociuu-dropdown [list]="falconChannels" [searchable]="false" [isLoadmore]="false"
                [selectedList]="selectedFalconChannelsOnEdit" [isCreateNew]="false" [isChips]="false" label="channels"
                [isCustomTrigger]="true" searchPlaceholder="Select channels" [customTriggerText]="'channel(s) applied'"
                (selectedItems)="selectedFalconChannels($event)" (loadmoreItems)="loadMoreChannels($event)">
              </sociuu-dropdown>
              <mat-spinner *ngIf="falconChannelsLoadingIndicator" diameter="20">Loading</mat-spinner>
            </div>

            <div class="mb-2">
              <sociuu-dropdown [list]="falconLabels" [searchable]="true" [selectedList]="selectedFalconLabelsOnEdit"
                [isCreateNew]="false" [isChips]="false" label="labels" [isCustomTrigger]="true" [isLoadmore]="false"
                searchPlaceholder="Select labels" [customTriggerText]="'label(s) applied'"
                (search)="searchFalconLabels($event)" (selectedItems)="selectedFalconLabels($event)" s>
              </sociuu-dropdown>
              <mat-spinner *ngIf="falconLabelsLoadingIndicator" diameter="20">Loading</mat-spinner>
            </div>

            <!-- <div>
              <sociuu-dropdown
                [list]="statusList"
                [selectedList]="selectedStatusListOnEdit"
                [searchable]="false"
                [isCreateNew]="false"
                [isChips]="false"
                label="statuses"
                [isCustomTrigger]="true"
                (selectedItems)="selectedStatusItems($event)"
                [customTriggerText]="'Statuses selected'"
              >
              </sociuu-dropdown>
              <div class="text-sm text-red-600" *ngIf="isStatusErrorEnabled">*This cannot be done, you need to select other options first.</div>
              <mat-spinner *ngIf="statusListLoadingIndicator" diameter="20">Loading</mat-spinner>
            </div> -->

            <mat-slide-toggle formControlName="falconVisibility">Show dark/hidden posts</mat-slide-toggle>
          </mat-expansion-panel>
        </ng-container>

        <div *ngIf="sourceType === 'emply'">
          <mat-form-field appearance="outline" class="width-100 no-padding-bottom mb-2">
            <mat-label>Client name</mat-label>
            <input matInput formControlName="emply_client_name" placeholder="Client name" />
          </mat-form-field>
        </div>

        <ng-container *ngIf="sourceType === 'personio'">
          <div class="personio-acc-domain">
            <div class="top-left-border width-100 no-padding-bottom border-0">
              <mat-form-field appearance="outline" class="width-100">
                <mat-label>Personio Account Name</mat-label>
                <input (blur)="onPersonioAccNameBlur()" matInput formControlName="personio_acc_name"
                  placeholder="e.g. {client}" />
              </mat-form-field>
            </div>
            <div class="top-right-border personio-domain width-3/4 no-padding-bottom">
              <mat-form-field appearance="outline">
                <mat-label>Domain</mat-label>
                <mat-select formControlName="personio_domain" (selectionChange)="updatePersonioDomain($event.value)">
                  <mat-option *ngFor="let domain of personioDomains" [value]="domain.value">
                    {{ domain.key | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="mb-3 flex mt-1 text-sm">
            <mat-error *ngIf="showRequiredError('personio_acc_name')">Account Name is required</mat-error>
            <ng-container *ngIf="shouldShowPersonioAccValidationStatus()">
              <ng-container *ngIf="isVerifyingAccName">
                <mat-spinner [diameter]="17" class="mr-3"></mat-spinner> Verifying Account Name
              </ng-container>
              <ng-container *ngIf="!isVerifyingAccName">
                <ng-container [ngTemplateOutlet]="validityStatus"
                  [ngTemplateOutletContext]="{ status: personioAccValidationStatus, field: 'name' }"></ng-container>
              </ng-container>
            </ng-container>
          </div>

          <mat-form-field appearance="outline" class="width-100 no-padding-bottom">
            <mat-label>Job Details URL</mat-label>
            <input matInput formControlName="personio_url" placeholder="e.g. https://company.com/jobs/{position_id}" />
            <div *ngIf="shouldAddShowInsertPositionID && !isPersonioDefaultURLEnabled" class="copy-fields-toggles"
              matSuffix>
              <ng-container [ngTemplateOutlet]="placeholdersMenu"></ng-container>
            </div>
          </mat-form-field>
          <div class="mt-1 text-sm">
            <mat-error *ngIf="!isPersonioDefaultURLEnabled && showRequiredError('personio_url')">URL is
              required</mat-error>
            <ng-container *ngIf="!showRequiredError('personio_url') && addSourcePayloadForm.get('personio_url')?.value">
              <!-- <ng-container *ngIf="isVerifyingAccURL">
                <mat-spinner [diameter]="17" class="mr-3"></mat-spinner> Verifying Account Name with domain
              </ng-container> -->
              <ng-container>
                <ng-container [ngTemplateOutlet]="validityStatus"
                  [ngTemplateOutletContext]="{ status: personioURLValidationStatus, field: 'url' }"></ng-container>
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="data.type !== 'EDIT'" class="mt-2 flex justify-between">
            <div class="personio-url-options">
              <span *ngIf="!isPersonioDefaultURLEnabled" (click)="toggleDefaultPersonioURL(true)">Insert Default
                Personio URL</span>
              <span *ngIf="isPersonioDefaultURLEnabled" (click)="toggleDefaultPersonioURL(false)">Insert Custom Personio
                URL</span>
            </div>
            <ng-container *ngIf="addSourcePayloadForm.get('personio_url')?.value && personioAccValidationStatus">
              <div class="preview-url">
                <ng-container *ngIf="isPersonioDefaultURLEnabled">
                  <ng-container [ngTemplateOutlet]="previewUrl"></ng-container>
                </ng-container>
                <ng-container *ngIf="!isPersonioDefaultURLEnabled && addSourcePayloadForm.get('personio_url')?.valid">
                  <ng-container [ngTemplateOutlet]="previewUrl"></ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header class="bg-white panel-height">
          <mat-panel-title class="font-normal text-xl"> Content actions </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="cotent-settings-body-container">
          <div class="mb-3 border-bottom">
            <div class="flex items-start justify-between">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Show in SociuuHub</p>
                <p class="text-sm">Enable to display this content in SociuuHub, making it
                  accessible to employees.</p>
              </div>
              <mat-slide-toggle formControlName="availableAll" (change)="makeContentPublic($event)"></mat-slide-toggle>
            </div>

            <div *ngIf="isPublic" class="flex items-start justify-between">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Auto-archive expired content</p>
                <p class="text-sm">Enable to automatically move expired content to an archive.
                  This helps keep your active content feed fresh and relevant.</p>
              </div>
              <mat-slide-toggle formControlName="auto_archive_expired_content"></mat-slide-toggle>
            </div>
          </div>

          <div *ngIf="!isEmplyDate" class="mb-3 flex items-start justify-between border-bottom">
            <div class="flex flex-col left-side">
              <p class="mb-1 font-medium text-base">Content Expiration</p>
              <p class="text-sm">Specify if and when content should automatically become
              inactive. Set the duration in days until expiration or choose
              never to expire.</p>
            </div>

            <div class="flex flex-col items-end">
              <mat-slide-toggle
                [matTooltip]="contentExpireEnabled ? 'Expiry enabled' : 'Expiry disabled'"
                formControlName="content_expire_enabled"
              ></mat-slide-toggle>
              <div class="flex mt-2 items-center">
                <div
                  [matTooltip]="contentExpireEnabled ? '' : 'Please enable the expiry toogle to set value'"
                  [ngClass]="{'disable-content-expire-input': !contentExpireEnabled}"
                  class="flex items-center w-max-content"
                >
                  <span>Expires in</span>
                  <input
                    mat-input
                    formControlName="content_expires_after_days"
                    [disabled]="!contentExpireEnabled"
                    class="w-8 border ml-2 mr-2 h-6 mb-0"
                    type="number"
                    min="0"
                  />
                  <span>days</span>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 flex items-start justify-between border-bottom">
            <div class="flex flex-col left-side">
              <p class="font-adjustment-label flex items-center mb-1">
                <span class="font-medium text-base">Direct post as default</span>
                <help [tooltip]="directShareTooltipText" [iconSize]="'sm'" [style.color]="'grey'"></help>
              </p>
              <p class="text-sm">All new contents coming in from this source will automatically be set as a direct share</p>
            </div>

            <div class="flex flex-col items-end">
              <mat-slide-toggle
                [matTooltip]="directShareEnabled ? 'Direct share enabled' : 'Direct share disabled'"
                formControlName="direct_share"
              ></mat-slide-toggle>
            </div>
          </div>

          <div *ngIf="isPublic && sourceType === 'emply'" class="mb-3 flex items-start justify-between border-bottom">
            <div class="flex flex-col left-side">
              <p class="mb-1 font-medium text-base">Use job post deadline as expiration</p>
              <p class="text-sm">Use the deadline in Emply as the expiration date for the job post in Sociuu Hub.</p>
            </div>
            <mat-slide-toggle formControlName="expires_at_deadline"></mat-slide-toggle>
          </div>

          <ng-container *ngIf="sourceType === 'personio'">
            <div class="mb-3 flex items-start justify-between border-bottom">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Personio Auto-archive</p>
                <p class="text-sm">If a Personio position is republished it will automatically be unarchived if it previously have been automatically been archived</p>
              </div>
              <mat-slide-toggle formControlName="autoArchive"></mat-slide-toggle>
            </div>

            <div class="mb-3 flex items-start justify-between border-bottom">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Personio Auto-unarchive</p>
                <p class="text-sm">If a Personio position is no longer available, it means it's been \'unpublished\' or \'archived\' in Personio and it will automatically be archived</p>
              </div>
              <mat-slide-toggle formControlName="autoUnarchive"></mat-slide-toggle>
            </div>
          </ng-container>

          <div *ngIf="(hasBrandwatchIntegration && sourceType !== 'falcon')" class="mb-3 border-bottom">
            <div class="flex items-start justify-between border-bottom">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Send to Brandwatch</p>
                <p class="text-sm">Enable to automatically send new Content Hub additions from this source to Brandwatch's Content Pool.</p>
              </div>
              <mat-slide-toggle formControlName="autoSendBW"></mat-slide-toggle>
            </div>

            <div *ngIf="addSourcePayloadForm?.get('autoSendBW')?.value" class="flex items-start justify-between">
              <div class="flex flex-col left-side">
                <p class="mb-1 font-medium text-base">Prevent duplicate</p>
                <p class="text-sm">By enabling this, you will see a ignore-on-sociuu label in Brandwatch</p>
              </div>
              <mat-slide-toggle formControlName="preventDuplicateBWContent"></mat-slide-toggle>
            </div>
          </div>

          <div *ngIf="mlImagesCount !== undefined" class="flex items-start justify-between border-bottom"
            [matTooltip]="mlImagesCount > 0 ? '' : 'You currently have no images in Image Library'"
            [ngClass]="{'no-images-available': mlImagesCount === 0}"
          >
            <div class="flex flex-col left-side">
              <p class="mb-1 font-medium text-base">Always use image from Image Library</p>
              <p class="text-sm"> Enable this feature to replace all incoming content images from
                this source with random images from your Image Library,
                regardless of original content</p>
            </div>
            <mat-slide-toggle class="media-lib-toggle" [disabled]="mlImagesCount === 0" formControlName="useMLImage"></mat-slide-toggle>
          </div>

          <!-- <fuse-alert *ngIf="!mlImagesCount && mlImagesCount !== undefined" [type]="'warning'" [appearance]="'outline'" class="bold">
            You currently have no images in Image Library
          </fuse-alert> -->

          <div class="mb-3 mt-3 border-bottom">
            <p class="font-adjustment-label flex items-center mb-1">
              <span class="font-medium text-base">Set active networks</span>
              <help [tooltip]="setActiveNetworksTooltipText" [iconSize]="'sm'" [style.color]="'grey'"></help>
            </p>
            <div>
              <div class="flex w-full flex-wrap row-gap mb-3" formArrayName="socialNetworks">
                <ng-container *ngFor="let socialNetwork of _socialNetworks.controls; index as i">
                  <div class="flex justify-between items-center flex-50 sm:flex-050">
                    <p class="w-40 flex items-center m-0">
                      <img style="width: 24px" src="{{ socialNetworks[i].social_icon_url }}" />
                      <span class="ml-1">{{ socialNetworks[i].title | titlecase }}</span>
                      <ng-container *ngIf="socialNetworks[i].title === 'instagram'">
                        <help [tooltip]="'Instagram can only be activated when you add a instagram source'" [iconSize]="'sm'" [style.color]="'grey'"></help>
                      </ng-container>
                    </p>
                    <div class="mr-3">
                      <mat-slide-toggle [formControlName]="i" class="ml-3"> </mat-slide-toggle>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="border-bottom">
            <p class="font-adjustment-label flex items-center mb-1">
              <span class="font-medium text-base">Set tags</span>
              <help [tooltip]="setTagsTooltipText" [iconSize]="'sm'" [style.color]="'grey'"></help>
            </p>
            <div class="no-padding-bottom">
              <tag-select
                [triggerType]="'selectDropdown'"
                [placeholder]="'Add tags'"
                [isMultiSelectEnabled]="true"
                [isCreateNewEntityEnabled]="true"
                (onSelectEmitter)="addTag($event)"
                [selectedEntities]="tagsModel"
              ></tag-select>
            </div>

            <ng-container *ngIf="!sourceTypeLoadingIndicator && data && data.type === 'EDIT' && hasTagsChanged">
              <div class="mb-3 mt-3 flex items-center" [matTooltip]="unchangedTagsInfo">
                <mat-checkbox formControlName="applyTagsChangesToExistingContent">
                  Apply tags changes to existing {{ data?.source?.content_count }} content
                </mat-checkbox>
              </div>
            </ng-container>
          </div>

        </div>
      </mat-expansion-panel>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex flex-row-reverse justify-start p-6 pt-6 pb-0 mb-0">
  <button type="submit" (click)="saveSource()" [disabled]="!addSourcePayloadForm.valid" mat-flat-button color="primary"
    class="btn-border-radius mr-2">
    {{ data.type == "CREATE" ? "Add source" : "Update source" }}
  </button>
  <button (click)="dismiss()" mat-button class="basic-color-button btn-border-radius mr-2">Cancel</button>
</mat-dialog-actions>


<ng-template #validityStatus let-status="status" let-field="field">
  <div *ngIf="status === personioVerificationStatuses.valid && field === 'name'" class="valid">
    <mat-icon class="icon-cap" svgIcon="heroicons_outline:check-circle"></mat-icon>
    <span>Verified</span>
  </div>
  <div *ngIf="status === personioVerificationStatuses.invalid" class="invalid">
    <mat-icon class="icon-cap" svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
    <span *ngIf="field === 'name'">The Personio Account name provided is incorrect</span>
    <span *ngIf="field === 'url'">The provided URL is not valid</span>
  </div>
  <div *ngIf="status === personioVerificationStatuses.warning" class="warning">
    <mat-icon class="icon-cap" svgIcon="heroicons_outline:exclamation-triangle"></mat-icon>
    <span>We couldn't find any open positions</span>
  </div>
</ng-template>

<ng-template #placeholdersMenu>
  <span [matMenuTriggerFor]="placeholderMenu" (click)="insertPositionId()"
    class="text-sm font-medium text-gray-400 cursor-pointer select-none">
    Insert Position ID
  </span>
</ng-template>

<ng-template #previewUrl>
  <div class="underline flex justify-end fuse-color">
    <button (click)="openURL()">Preview URL</button>
  </div>
</ng-template>
